import React, { useState, useEffect } from 'react'
import "./ServiceToolbar.scss"

import SteveSVG from "../../../images/svg/arrastre-stevedoring-2.svg";
import LoadSVG from "../../../images/svg/loading.svg";
import WHSVG from "../../../images/svg/warehouse.svg";
import CargoSVG from "../../../images/svg/cargo-delivery.svg";
import TerminalSVG from "../../../images/svg/terminal-management.svg";
import ITSVG from "../../../images/svg/it-services.svg";
import { MobileView } from 'react-device-detect';

const ServiceToolbar = () => {
    const [path, setPath] = useState(null);

    useEffect(() => {
        const link = window.location.pathname;

        setPath(link.replace(/\/+$/, '')); //
    })

    const services = [
        {
            id: 1,
            name: "Arrastre and Stevedoring",
            svg: SteveSVG,
            link: "/stevedoring-and-arrastre",
        },
        {
            id: 2,
            name: "Loading",
            svg: LoadSVG,
            link: "/loading",
        },
        {
            id: 3,
            name: "Transit Storage",
            svg: WHSVG,
            link: "/transit-storage",
        },
        {
            id: 4,
            name: "Cargo Delivery",
            svg: CargoSVG,
            link: "/cargo-delivery",
        },
        {
            id: 5,
            name: "Terminal Management",
            svg: TerminalSVG,
            link: "/terminal-management",
        },
        {
            id: 6,
            name: "IT Services",
            svg: ITSVG,
            link: "/it-services",
        },
    ]

    return(
        <MobileView>
            <div className='service-toolbar'>
                <div className='d-flex flex-row toolbar'>
                    { services.map((serv, i) => 
                        <div key={i} 
                        className={path == serv.link && path != null ? "active" : ""}
                        >
                            <a href={serv.link}>
                                <img width={40} src={serv.svg} alt={serv.name} />
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </MobileView>
    )
}

export default ServiceToolbar